import React, { Fragment } from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import PropTypes from 'prop-types';
// import qqdsGif from './images/qqsy.gif';
import { formatUrl, formatImage } from '@ifeng-fe/public_method';

// import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        contentList: [1, 2, 3, 4],
        activeIndex: 0,
        isCodeShow: false,
    };

    handleOnMouseOver = i => {
        clearInterval(this.interval);

        this.setState(() => ({
            activeIndex: i,
        }));
    };

    handleOnMouseOut = i => {
        this.interval = setInterval(() => this.tick(), 2000);
    };

    tick() {
        if (this.state.activeIndex >= this.state.contentList.length - 1) {
            this.setState(() => ({
                activeIndex: 0,
            }));
        } else {
            this.setState(prevState => ({
                activeIndex: prevState.activeIndex + 1,
            }));
        }
    }

    handleClose() {
        this.setState(() => ({
            isCodeShow: false,
        }));
    }

    bindHandleScroll(event) {
        const scrollTop = event.srcElement ? event.srcElement.documentElement.scrollTop : false;

        if (scrollTop > 855) {
            this.setState(() => ({
                isCodeShow: true,
            }));
        } else {
            this.setState(() => ({
                isCodeShow: false,
            }));
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 2000);

        window.addEventListener('scroll', this.bindHandleScroll.bind(this));
    }

    componentWillUnmount() {
        clearInterval(this.interval);

        window.removeEventListener('scroll', this.bindHandleScroll.bind(this));
    }

    render() {
        const { eventScene } = this.props.content;

        const { activeIndex, isCodeShow } = this.state;

        return (
            <div className={styles.wrap}>
                <div className={styles.top}>
                    {/* <div className={styles.box}>
                        <div className={styles.top_left}>
                            <img src={require('./images/logo.png')} alt="" className={styles.logo} />
                            <div className={styles.homeLine} />
                            <div className={styles.title}>
                                中华情怀<span className={styles.dian}>·</span>
                                全球视野<span className={styles.dian}>·</span>
                                包容开放<span className={styles.dian}>·</span>
                                进步力量
                            </div>
                            <img src={require('./images/code.png')} alt="" className={styles.code} />
                            <div className={styles.smDownLoad}>
                                <img src={require('./images/sys.png')} />
                                <span className={styles.kfhws}>扫码下载</span>
                            </div>
                        </div>
                        <div className={styles.top_right}>
                            <img src={require('./images/mobile_new.png')} alt="" />
                        </div>
                    </div> */}
                    <img
                        className={styles.video_player}
                        src="https://x0.ifengimg.com/ucms/2024_08/E81AB6653FDACB2F09F7ACF4D4590E9CDA636300_size273_w750_h1556.jpg"
                        alt=""
                    />
                    {/* <video
                        className={styles.video_player}
                        src="https://x0.ifengimg.com/fe/custom/2022_03_24/download.fe44354f7666.mp4"
                        autoPlay
                        loop
                        muted
                    /> */}
                    {/* <video className={styles.video_player} src="https://x0.ifengimg.com/fe/custom/2022_03_17/mobile_a.04cfe4077cc0.mp4"  ></video> */}
                </div>
                {/* <div className={styles.center}>
                    <div className={styles.headTitle}>全球视野 纵览全局 </div>
                    <img src={qqdsGif} alt="" />
                </div> */}
                <div className={styles.bottom}>
                    <div className={styles.headTitle}>大事现场 从未缺席</div>
                    <div className={styles.bottomContent}>
                        <div className={styles.left}>
                            <div className={styles.timeZhow} />
                            <div className={styles.leftContent}>
                                {eventScene.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={styles.left_item}
                                            onMouseOver={this.handleOnMouseOver.bind(this, index)}
                                            onMouseOut={this.handleOnMouseOut.bind(this, index)}>
                                            <div
                                                className={`${
                                                    activeIndex === index ? `${styles.activeTime}` : `${styles.time}`
                                                } ${styles.timeWid}`}>
                                                <span className={styles.cycle} />
                                                <span className={styles.timeText}>{item.url}</span>
                                            </div>
                                            <div
                                                className={`${
                                                    activeIndex === index ? `${styles.activeTitle}` : `${styles.title}`
                                                }`}>
                                                {item.title}
                                            </div>
                                            <div
                                                className={`${
                                                    activeIndex === index
                                                        ? `${styles.activeSummary}`
                                                        : `${styles.summary}`
                                                }`}>
                                                {item.summary}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={styles.right}>
                            <img src={formatImage(eventScene[activeIndex].thumbnail)} alt="" />
                        </div>
                    </div>
                </div>
                <div className={styles.foot}>
                    <div className={styles.footInner}>
                        <div className={styles.footLogo}>
                            <img src={require('./images/foot_logo.png')} />
                        </div>
                        <div className={styles.company}>
                            <div>
                                公司：北京天盈九州网络技术有限公司
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司地址：北京市朝阳区望京启阳路4号中轻大厦
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司电话：010-60676000
                            </div>
                        </div>
                        <div className={styles.copyright}>
                            凤凰新媒体 版权所有 Copyright © 2021 Phoenix New Media Limited All Rights Reserved.
                        </div>
                    </div>
                </div>
                {isCodeShow && (
                    <div className={styles.scanCode}>
                        <div className={styles.close} onClick={this.handleClose.bind(this)}>
                            关闭
                        </div>
                        <div className={styles.title}>凤凰新闻客户端</div>
                        <div className={styles.code}>
                            <img src={require('./images/code.png')} />
                        </div>
                        <div className={styles.tip}>
                            <div className={styles.sys} />
                            <div className={styles.sysDown}>扫描二维码下载</div>
                        </div>
                    </div>
                )}
                {/* <Chip id="200108" type="struct" title="下载链接配置" groupName="正文" content={downloadLinks}>
                    <DownloadUrl />
                </Chip> */}
                {/* <ChipEdit transform={transform} /> */}
            </div>
        );
    }
}

export default errorBoundary(Layout);
